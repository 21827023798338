import React, { useEffect, useState } from 'react'

import { Button, Form, Grid } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { parse } from 'query-string'
import { useLocation } from '@reach/router'
import { FormikProps, useFormik } from 'formik'
import { navigate } from 'gatsby'

import Banner from 'src/components/shared/Banner'
import { getErrorWithBkgMessage, hasAllValues, validateEmail } from 'src/utils/form-utils'

import Pvolve from '@pvolve/sdk'

import * as authStyles from 'src/styles/auth.module.scss'
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils'
import { ErrorOutlineOutlined } from '@mui/icons-material'
import Actions from 'src/state/root-actions'
import { useDispatch } from 'react-redux'

interface FormValues {
    email: string
    general?: string
}

interface EmailFormProps {
    buttonLabel?: string
    email?: string
}

const EmailForm = ({ buttonLabel = 'Next' }: EmailFormProps & FormikProps<FormValues>) => {
    const location = useLocation()
    const params = parse(location?.search)
    const [userInfo, setUserInfo] = useState({})
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate: validateEmail,
        onSubmit: async (values, actions) => {
            const email = values.email.toLowerCase()

            try {
                const user = await Pvolve.api.account.findUserByEmail(email)
                const firstName = user.given_name && user.given_name !== 'First_Name' ? user.given_name : undefined

                if (user?.status === 'CONFIRMED' || user?.status === 'EXTERNAL_PROVIDER') {
                    navigate('/login', {
                        state: {
                            email: email,
                            firstName,
                            status: user?.status,
                            provider: user?.provider,
                        },
                    })
                }
                if (user?.status === 'UNCONFIRMED') {
                    dispatch(
                        Actions.auth.resendConfirmationCode.trigger({
                            email,
                        })
                    )
                }
                setUserInfo(user)
            } catch (error) {
                if (error?.response?.status === 404) {
                    navigate('/signup', {
                        state: {
                            email,
                        },
                    })
                } else {
                    const errMsg = error?.message || 'Oops! Something went wrong. Please try again.'
                    actions.setFieldError('general', errMsg)
                }
            }
        },
    })
    const errors = formik.errors
    const touched = formik.touched

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (params?.error_description?.match(/Account already exists/i)) {
            formik.setFieldError('general', 'Account with email already exists, please CONTINUE WITH EMAIL below.')
        }
    }, [])

    const { widths } = SUI_GRID_CENTERED

    const onChangeEmail = val => {
        formik.handleChange('email')(val)
        setUserInfo({})
    }

    return (
        <Form className={authStyles.emailForm} onSubmit={formik.handleSubmit} noValidate>
            <Grid container centered>
                <Grid.Row>
                    <Grid.Column mobile={widths.mobile} tablet={widths.tablet} computer={widths.computer}>
                        <Form.Input
                            id="login-email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            className={`${authStyles.input} ${authStyles.inputEmail}`}
                            onChange={onChangeEmail}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={errors.email && touched.email ? getErrorWithBkgMessage(errors.email) : false}
                        />
                        {errors?.general && <Banner type="ERROR">{errors.general}</Banner>}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={widths.mobile} tablet={widths.tablet} computer={widths.computer}>
                        <Button
                            type="submit"
                            disabled={!hasAllValues(formik.values) || !isEmpty(errors)}
                            primary
                            fluid
                            loading={formik.isSubmitting}
                            className={authStyles.submitButton}
                        >
                            {buttonLabel}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                {'UNCONFIRMED' === userInfo?.status && (
                    <Grid.Row>
                        <Grid.Column mobile={widths.mobile} tablet={widths.tablet} computer={widths.computer}>
                            <div className={authStyles.emailNotification}>
                                <ErrorOutlineOutlined />
                                <div className="desc">
                                    <b>It looks like you haven’t created a password yet.</b>&nbsp;
                                    <span>We sent an email to</span>&nbsp;
                                    <b>{formik.values.email}</b>
                                    <span>. Click on the link in the email to finish setting up your account.</span>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Form>
    )
}

export default EmailForm
